import React from "react";
import Page from "../components/Page";
import Record from "../components/Record";

export default function Blog() {
  return <Page 
  
    title={"Blog"}
    content={
      <>
      <div>
        <p>
        I deleted Instagram because the features weren't serving me to use my time wisely (i.e. Reels and the Explore pages). Instead, catch up with me here!
        </p>
        <br/>
        <p>
          Under construction, coming soon 😊
        </p>

        
        {/* <Record />
        <Record />
        <Record /> */}
      </div>
      
      </>
    } />;
}
