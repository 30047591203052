import React from "react";
import { NavLink } from "react-router-dom";
import "../App.css";

export default function Navbar() {
  return (
    <div className="Navbar flex flex-row justify-between">
      <div className="HomeLink">
        <NavLink to="/" end>
          <b>Marthen Nodado</b>
        </NavLink>
      </div>
      <div className="Links flex flex-row justify-items-end gap-8">
        <div><NavLink to="/about" end>About</NavLink></div>
        <div><NavLink to="/projects" end>Projects</NavLink></div>
        <div><NavLink to="/music" end>Music</NavLink></div>
        <div><NavLink to="/blog" end>Blog</NavLink></div>
      </div>
    </div>
  );
}
