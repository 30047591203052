import React from "react";
import Page from "../components/Page";

export default function Projects() {

	const projects = [
		{
			name: "Predictive Maintenance Dashboard for Fixed Robots",
			tag: "web",
			image: "https://www.dropbox.com/s/af2cbyorohc0k9b/pmd.png?raw=1",
			href: "https://mnodado.notion.site/Senior-Capstone-Portfolio-5820ff6c901f44df91787bf0c80a2fbf?pvs=4"
		},
		{
			name: "Flix",
			tag: "ios",
			image: "https://www.dropbox.com/scl/fi/rvz5tthre22czzj6t8d2v/flix.png?rlkey=8t2i29tx1habfxkwy3cp3zove&raw=1",
			href: "https://github.com/illumimarty/flix-swiftui"
		},
		{
			name: "Gracious",
			tag: "ios",
			image: "https://www.dropbox.com/s/s6rjpuq2yok0e25/gracious.png?raw=1",
			href: "https://github.com/illumimarty/ios-tipcalculator-gracious"
		},
		{
			name: "Imahen",
			tag: "ios",
			image: "https://www.dropbox.com/scl/fi/fquvs5boyli2qbzhr8s1h/imahen-preview.png?rlkey=edrsb3rdbopcw7uzw8ihfr5al&raw=1",
			href: "https://github.com/illumimarty/Imahen2"
		}
	];

  const onHandleClick = (link) => {
		window.open(link)
	};
	

  return <Page 

    title={"Selected Works"}
    content={
    // <>Projects</>
    <section class="py-8 md:py-0" id="projectSection">
    {/* <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-4xl dark:text-white">
      Selected Works
    </h1> */}
    {/* <div class="md:flex items-center justify-center py-4 md:py-4 flex-wrap">
          <button type="button" class="text-blue-700 hover:text-white border border-blue-600 bg-white hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full text-base font-medium px-5 py-2.5 text-center me-3 mb-3 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:bg-gray-900 dark:focus:ring-blue-800">All</button>
          <button type="button" class="text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center me-3 mb-3 dark:text-white dark:focus:ring-gray-800">Web</button>
          <button type="button" class="text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center me-3 mb-3 dark:text-white dark:focus:ring-gray-800">iOS</button>
    </div> */}
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        {projects.map((item) => (
          <button onClick={(e) => {
            onHandleClick(item.href)
          }}>

            <div class="group transition-all relative filter cursor-pointer  inset-0 z-0">
              <img class="h-auto duration-300 max-w-full rounded-lg grayscale-0 group-hover:grayscale brightness-100 group-hover:brightness-25" src={item.image} alt="" />
                <div class="opacity-0 duration-300 group-hover:brightness-100 group-hover:opacity-100 absolute flex justify-center text-xl md:text-xl xl:text-2xl 2xl:text-6xl items-center text-white font-semibold inset-0 z-10"> 
                  {item.name}
              </div>
            </div>
          </button>
        ))}
      </div>
  </section>
  } />;
}
