import React from "react";
import Page from "../components/Page";
import "../App.css";

export default function Music() {
  return <Page 
    title={"Music"}
    content={
      <>
        <div className="pb-8">
          <h2 className="text-2xl">Classical</h2>
          <p>I've been involved with music for almost my whole life. I also play the French Horn with the <a className="text-blue-600 after:content-['_↗']" href="https://myso.live">Mariposa Yosemite Symphony Orchestra</a> since 2023. Check out what I'll be playing for our upcoming concert!</p>
          <div className="flex flex-col md:flex-row md:gap-8 md:justify-center">            
            <div className="py-4 grid order-2 gap-4 grid-cols-2 md:grid-cols-1 md:gap-4 md:max-w-sm md:order-1">
              <div><img className="" src="https://i.imgur.com/QNGcK3r.jpg" alt="me and my french horn friends in a 2023 indoor concert" /></div>
              <div><img className="" src="https://i.imgur.com/5vDiJ8y.jpg" alt="me and my french horn friends in a 2024 outdoor concert"/></div>
            </div>
            <iframe id="myso" title="myso" className="Spotify py-4 order-1 md:order-2" src="https://open.spotify.com/embed/playlist/0wBpYkmzctfQGfNIu05Z9s?utm_source=generator" width="50%" height="full" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
        </div>
        <div className="">
          <h2 className="text-2xl">Joy Listening</h2>
          {/* <p>My music taste is also all over the place, but lately these have been my go-to's.</p> */}
          <div className="grid grid-rows-2 md:flex md:flex-row md:gap-4 justify-items-stretch">
            <iframe className="Spotify py-4" title="playlist1" src="https://open.spotify.com/embed/playlist/06OvWTxJoJgMMls4pcdf0C?utm_source=generator&theme=0" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            {/* <iframe className="Spotify py-4" src="https://open.spotify.com/embed/playlist/5K5d4npMZvBn1bmckwAKHV?utm_source=generator" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe> */}
            <iframe className="Spotify py-4" title="playlist2" src="https://open.spotify.com/embed/playlist/18ZC33cI7VslhUP81YDJPp?utm_source=generator" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
        </div>        
      </>
    } 
  />;
}
