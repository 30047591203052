import React from "react";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";

export default function About() {
  return <Page t
    title={"...but you can call me Marty."}
    content={
    // <>About</>
    <section class="sm:pb-8 lg:pb-20 lg:mx-auto" id="aboutSection">
    <div className="flex-none sm:min-w-fit lg:flex lg:justif md:flex-row lg:gap-0 sm:gap-0 pb-8 md:place-content-center">
      <div class="flex place-content-center md:flex-none sm:shrink-0 order-2 ">
        <img
            class="scale-50 max-h-full max-w-xs m-auto md:max-w-md md:scale-75"
            src="https://i.imgur.com/o2WDMNY.jpg"
            alt="profile pic"
        />
      </div>
      <div class="flex lg:max-w-2xl lg:px-3 py-6 text-left self-center flex-auto order-1 place-content-center">
        <div class="container px-6 md:px-8">
          {/* <h2 class="text-4xl font-semibold py-2 text-gray-900 dark:text-white">...but you can call me Marty.</h2> */}
          <p class="text-xl py-2 text-gray-900">I’m a software engineer with a specialty in iOS development. I studied Computer Science at UC Merced, and I grew up in Northern California. </p>
          <p class="text-xl py-2 text-gray-900">By day I'm an IT professional in higher education. By night, I build <NavLink to="/projects" className="text-blue-600" end><u>web and mobile apps</u></NavLink> to indulge on curiosities or to make managing life a bit sweeter.</p>
          <p class="text-xl py-2 text-gray-900">Aside from tech, I'm either playing <NavLink to="/music" className="text-blue-600" end><u>a mean French horn</u></NavLink>, bingewatching <i>Friends</i>, fiddling with Notion pages, downing cappuchinos, or making excuses to go out to eat kimchi. </p>
          <p class="text-xl py-2 text-gray-900">🇵🇭</p>
        </div>
      </div>
    </div>
  </section>
  } />;
}
