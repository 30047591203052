import React from "react";
import "../App.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function Page({title, content}) {
  return(
    <>
      <Navbar />
      <div className="Content flex-grow">
        <div className="Header">
          <div className="Title">
            <b>{title}</b>
          </div>
        </div>
        <div className="Body flex-grow">
        {content}
        </div>
      </div>
      <Footer />
    </>
  );
};