import React from "react";
import "../App.css";

export default function Footer() {
  return (
    <div className="Footer flex flex-row justify-center">
      <div className="Links flex flex-row gap-4">
        <div>
          <a href="mailto:me@mnodado.com">Email</a>
        </div>
        <div>
          <a href="https://linkedin.com/in/marthen-r-nodado">LinkedIn</a>
        </div>
        <div>
          <a href="https://github.com/illumimarty">Github</a>
        </div>
      </div>
    </div>
  );
}
