import React from "react";
import Page from "../components/Page";
import Record from "../components/Record";
import { NavLink } from "react-router-dom";

export default function Home() {
  return(
    <Page 
      title={"Hiya 👋🏽 I'm Marthen—a software engineer based in the SF Bay Area."}
      content={
        <>
        <br/>
        <div className="">
          <NavLink to="/about" className={"text-blue-600 after:content-['_↗']"} end><u>About me</u></NavLink>
          <br/>
          <br/>
          <NavLink to="/projects" className={"text-blue-600 after:content-['_↗']"} end><u>What I've worked on</u></NavLink>
          <br/>
          <br/>
          <NavLink to="/music" className={"text-blue-600 after:content-['_↗']"} end><u>What's been stuck in my head</u></NavLink>
        </div>
        <br></br>
        {/* <Record />
        <Record />
        <Record /> */}
        </>
    }/>
  );
};